import React, { useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { usePortfolio } from "@/hooks/usePortfolio";
import { RefreshCw } from "lucide-react";
import { Button } from "@/components/ui/button";
interface SummaryCardProps {
  title: string;
  value: string;
  change?: {
    value: string;
    positive: boolean;
  };
  subtitle?: string;
}
const SummaryCard = ({
  title,
  value,
  change,
  subtitle
}: SummaryCardProps) => <Card className="h-full">
    <CardHeader className="pb-2">
      <CardTitle className="text-sm font-medium flex items-center justify-between">
        {title}
      </CardTitle>
    </CardHeader>
    <CardContent>
      <div className={`text-2xl font-bold ${change?.positive ? "text-green-600" : change?.positive === false ? "text-red-600" : ""}`}>
        {value}
      </div>
      {change && <p className="text-xs text-muted-foreground">
          <span className={change.positive ? "text-green-600" : "text-red-600"}>
            {change.positive ? "↑" : "↓"} {change.value}
          </span>
          {subtitle && ` ${subtitle}`}
        </p>}
      {!change && subtitle && <p className="text-xs text-muted-foreground">{subtitle}</p>}
    </CardContent>
  </Card>;
const PortfolioSummary = () => {
  const {
    portfolioSummary,
    isLoading,
    fetchLatestPrices
  } = usePortfolio();
  const [isRefreshing, setIsRefreshing] = useState(false);

  // Format values for display
  const formatCurrency = (value: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(value);
  };
  const formatPercentage = (value: number) => {
    return value.toFixed(2) + '%';
  };
  const handleRefreshPrices = async () => {
    setIsRefreshing(true);
    await fetchLatestPrices();

    // Add a small delay to show the refreshing state
    setTimeout(() => {
      setIsRefreshing(false);
    }, 1000);
  };
  if (isLoading) {
    return <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Card className="h-full animate-pulse">
          <CardHeader className="pb-2">
            <div className="h-5 bg-gray-200 rounded w-36"></div>
          </CardHeader>
          <CardContent>
            <div className="h-8 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-32"></div>
          </CardContent>
        </Card>
        <Card className="h-full animate-pulse">
          <CardHeader className="pb-2">
            <div className="h-5 bg-gray-200 rounded w-36"></div>
          </CardHeader>
          <CardContent>
            <div className="h-8 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-32"></div>
          </CardContent>
        </Card>
        <Card className="h-full animate-pulse">
          <CardHeader className="pb-2">
            <div className="h-5 bg-gray-200 rounded w-36"></div>
          </CardHeader>
          <CardContent>
            <div className="h-8 bg-gray-200 rounded w-24 mb-2"></div>
            <div className="h-4 bg-gray-200 rounded w-32"></div>
          </CardContent>
        </Card>
      </div>;
  }
  return <div className="space-y-4">
      <div className="flex justify-end items-center">
        
      </div>
      
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <SummaryCard title="Total Portfolio Value" value={formatCurrency(portfolioSummary.totalValue || 0)} change={{
        value: formatPercentage(portfolioSummary.dailyChange || 0),
        positive: (portfolioSummary.dailyChange || 0) > 0
      }} subtitle="today" />
        <SummaryCard title="Top Performer" value={portfolioSummary.topPerformer && portfolioSummary.topPerformer.symbol ? portfolioSummary.topPerformer.symbol : "N/A"} change={portfolioSummary.topPerformer && portfolioSummary.topPerformer.change ? {
        value: formatPercentage(portfolioSummary.topPerformer.change || 0),
        positive: (portfolioSummary.topPerformer.change || 0) > 0
      } : undefined} />
        <SummaryCard title="Worst Performer" value={portfolioSummary.worstPerformer && portfolioSummary.worstPerformer.symbol ? portfolioSummary.worstPerformer.symbol : "N/A"} change={portfolioSummary.worstPerformer && portfolioSummary.worstPerformer.change ? {
        value: formatPercentage(portfolioSummary.worstPerformer.change || 0),
        positive: (portfolioSummary.worstPerformer.change || 0) > 0
      } : undefined} />
      </div>
    </div>;
};
export default PortfolioSummary;