import React from "react";
import { Table, TableBody, TableCaption, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { RefreshCw, Radio, HelpCircle } from "lucide-react";
import SignalCard, { SignalData } from "./SignalCard";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "@/components/ui/tooltip";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { useIsMobile } from "@/hooks/use-mobile";
interface SignalTableProps {
  signals: SignalData[];
  onRefresh?: () => void;
  isLive?: boolean;
}
const SignalTable = ({
  signals,
  onRefresh,
  isLive = false
}: SignalTableProps) => {
  const isMobile = useIsMobile();
  return <div className="rounded-md border overflow-hidden">
      <div className="bg-muted/20 p-2 flex items-center justify-between border-b">
        <div className="flex items-center">
          {isLive && <div className="flex items-center text-xs text-muted-foreground mr-2" aria-live="polite">
              <span className="relative flex h-2 w-2 mr-1" aria-hidden="true">
                <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"></span>
                <span className="relative inline-flex rounded-full h-2 w-2 bg-green-500"></span>
              </span>
              <span>Live Prices</span>
            </div>}
        </div>
        {onRefresh}
      </div>
      <ScrollArea className="w-full">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Symbol</TableHead>
              <TableHead>Signal</TableHead>
              <TableHead className="hidden md:table-cell">Entry Price</TableHead>
              <TableHead className="hidden md:table-cell">Stop Loss</TableHead>
              <TableHead className="hidden md:table-cell">Target</TableHead>
              <TableHead className="hidden md:table-cell">Current Price</TableHead>
              <TableHead>PnL (%)</TableHead>
              <TableHead className="hidden md:table-cell">
                <div className="flex items-center space-x-1">
                  <span>NEO Score</span>
                  <Dialog>
                    <DialogTrigger asChild>
                      <Button variant="ghost" size="icon" className="h-5 w-5 p-0" aria-label="NEO Score Information">
                        <HelpCircle className="h-3.5 w-3.5" aria-hidden="true" />
                      </Button>
                    </DialogTrigger>
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>About NEO Score</DialogTitle>
                        <DialogDescription>
                          <p className="mt-2">
                            NEO Score (New Entry Odds) is our proprietary metric that evaluates the probability of success for new trading entries.
                          </p>
                          <div className="mt-4 space-y-2">
                            <h4 className="font-medium">How to interpret NEO Score:</h4>
                            <ul className="list-disc pl-5 space-y-1">
                              <li><span className="font-medium">80-100:</span> Very high confidence, excellent entry point</li>
                              <li><span className="font-medium">60-79:</span> Strong confidence, favorable entry point</li>
                              <li><span className="font-medium">40-59:</span> Moderate confidence, acceptable entry point</li>
                              <li><span className="font-medium">20-39:</span> Low confidence, caution advised</li>
                              <li><span className="font-medium">0-19:</span> Very low confidence, high risk</li>
                            </ul>
                          </div>
                          <p className="mt-4 text-sm text-muted-foreground">
                            NEO Score combines technical indicators, market sentiment, trend strength, and volatility 
                            metrics to provide a comprehensive assessment of potential trade outcomes.
                          </p>
                        </DialogDescription>
                      </DialogHeader>
                    </DialogContent>
                  </Dialog>
                </div>
              </TableHead>
              <TableHead className="text-right">Analysis</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {signals.length > 0 ? signals.map(signal => <SignalCard key={signal.id} signal={signal} />) : <TableRow>
                <TableCell colSpan={isMobile ? 4 : 9} className="text-center py-6 text-muted-foreground">
                  No trading signals available at this time
                </TableCell>
              </TableRow>}
          </TableBody>
        </Table>
      </ScrollArea>
    </div>;
};
export default SignalTable;