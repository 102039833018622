import { useState, useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { supabase } from "@/integrations/supabase/client";
import { useToast } from "@/hooks/use-toast";
import { useAuth } from "@/contexts/AuthContext";
import { useMarketPrices } from "./useMarketPrices";

// Define the raw database type that comes directly from Supabase
interface PortfolioHoldingRaw {
  id: string;
  user_id: string;
  symbol: string;
  quantity: number;
  entry_price: number;
  entry_date: string | null;
  last_updated: string | null;
  notes: string | null;
  transaction_type: 'buy' | 'sell';
  current_price?: number;
}

// Define the enhanced type with calculated properties
export interface PortfolioHolding {
  id: string;
  user_id: string;
  symbol: string;
  quantity: number;
  entry_price: number;
  entry_date: string | null;
  last_updated: string | null;
  notes: string | null;
  transaction_type: 'buy' | 'sell';
  current_price?: number;
  change?: number;
  allocation?: number;
  value?: number;
}

export const usePortfolio = () => {
  const { user } = useAuth();
  const { toast } = useToast();
  const queryClient = useQueryClient();
  const { getPrice, refreshPrices, getPriceForSymbols } = useMarketPrices();
  
  const pendingUpdateRef = useRef<boolean>(false);
  
  const { data: holdings = [], isLoading, error, refetch } = useQuery({
    queryKey: ['portfolio-holdings'],
    queryFn: async () => {
      if (!user) return [];
      
      const { data, error } = await supabase
        .from('portfolio_holdings')
        .select('*')
        .order('symbol');
        
      if (error) {
        console.error('Error fetching portfolio holdings:', error);
        toast({
          title: "Error",
          description: "Failed to load portfolio data",
          variant: "destructive",
        });
        return [];
      }
      
      // Ensure all portfolio symbols are being tracked
      if (data.length > 0) {
        const symbols = data.map(h => h.symbol);
        console.log("Portfolio symbols:", symbols);
        // Track symbols but don't fetch prices automatically
        getPriceForSymbols(symbols).catch(err => {
          console.error('Error tracking portfolio symbols:', err);
        });
      }
      
      // Ensure we're treating the raw data with the correct type
      const rawHoldings = data as PortfolioHoldingRaw[];
      
      const holdings = rawHoldings.map(holding => {
        const entryPrice = Number(holding.entry_price);
        // Get current price from market data or fall back to entry price
        const marketPrice = getPrice(holding.symbol);
        const currentPrice = marketPrice?.current_price || 
          (holding.current_price !== undefined && holding.current_price !== null ? 
            Number(holding.current_price) : entryPrice);
        const value = Number(holding.quantity) * currentPrice;
        
        return {
          ...holding,
          quantity: Number(holding.quantity),
          entry_price: entryPrice,
          current_price: currentPrice,
          value,
          change: ((currentPrice - entryPrice) / entryPrice) * 100
        };
      });
      
      const totalValue = holdings.reduce((sum, h) => sum + (h.value || 0), 0);
      return holdings.map(h => ({
        ...h,
        allocation: totalValue > 0 ? (h.value! / totalValue) * 100 : 0
      }));
    },
    enabled: !!user,
    staleTime: 5 * 60 * 1000, // 5 minutes cache
  });
  
  // Function to fetch latest prices - manual only now
  const fetchLatestPrices = async () => {
    if (!user || !holdings.length || pendingUpdateRef.current) return;
    
    console.log('Fetching latest prices for', holdings.length, 'symbols');
    pendingUpdateRef.current = true;
    
    try {
      // Get symbols from holdings
      const symbols = holdings.map(h => h.symbol);
      
      // Call the refresh prices function from useMarketPrices
      await refreshPrices(symbols);
      
      // Delay the refetch slightly to allow database updates to complete
      setTimeout(() => {
        refetch();
        pendingUpdateRef.current = false;
      }, 2000);
    } catch (error) {
      console.error('Error updating portfolio prices:', error);
      pendingUpdateRef.current = false;
    }
  };

  const addHolding = useMutation({
    mutationFn: async (newHolding: Omit<PortfolioHolding, 'id' | 'user_id'>) => {
      if (!user) throw new Error("User not authenticated");
      
      const { data, error } = await supabase
        .from('portfolio_holdings')
        .insert({
          user_id: user.id,
          symbol: newHolding.symbol.toUpperCase(),
          quantity: newHolding.quantity,
          entry_price: newHolding.entry_price,
          entry_date: newHolding.entry_date || new Date().toISOString(),
          transaction_type: newHolding.transaction_type || 'buy',
          notes: newHolding.notes
        })
        .select()
        .single();
        
      if (error) {
        console.error('Error adding portfolio holding:', error);
        throw error;
      }
      
      await supabase
        .from('portfolio_transactions')
        .insert({
          user_id: user.id,
          holding_id: data.id,
          transaction_type: data.transaction_type,
          symbol: data.symbol,
          quantity: data.quantity,
          price: data.entry_price,
          transaction_date: data.entry_date,
          notes: `Initial ${data.transaction_type} of ${data.symbol}`
        });
        
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['portfolio-holdings'] });
      toast({
        title: "Success",
        description: "New position added to your portfolio",
      });
      
      fetchLatestPrices();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to add position: ${error.message}`,
        variant: "destructive",
      });
    }
  });

  const updateHolding = useMutation({
    mutationFn: async (holding: Partial<PortfolioHolding> & { id: string }) => {
      if (!user) throw new Error("User not authenticated");
      
      const { data, error } = await supabase
        .from('portfolio_holdings')
        .update({
          symbol: holding.symbol?.toUpperCase(),
          quantity: holding.quantity,
          entry_price: holding.entry_price,
          entry_date: holding.entry_date,
          transaction_type: holding.transaction_type,
          notes: holding.notes,
          last_updated: new Date().toISOString()
        })
        .eq('id', holding.id)
        .eq('user_id', user.id)
        .select()
        .single();
        
      if (error) {
        console.error('Error updating portfolio holding:', error);
        throw error;
      }
      
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['portfolio-holdings'] });
      toast({
        title: "Success",
        description: "Portfolio position updated",
      });
      
      fetchLatestPrices();
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to update position: ${error.message}`,
        variant: "destructive",
      });
    }
  });

  const deleteHolding = useMutation({
    mutationFn: async (holdingId: string) => {
      if (!user) throw new Error("User not authenticated");
      
      const { error } = await supabase
        .from('portfolio_holdings')
        .delete()
        .eq('id', holdingId)
        .eq('user_id', user.id);
        
      if (error) {
        console.error('Error deleting portfolio holding:', error);
        throw error;
      }
      
      return holdingId;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['portfolio-holdings'] });
      toast({
        title: "Success",
        description: "Position removed from your portfolio",
      });
    },
    onError: (error) => {
      toast({
        title: "Error",
        description: `Failed to delete position: ${error.message}`,
        variant: "destructive",
      });
    }
  });

  const portfolioSummary = {
    totalValue: holdings.reduce((sum, h) => sum + (h.value || 0), 0),
    dailyChange: holdings.length ? 
      holdings.reduce((sum, h) => sum + (h.change || 0) * (h.value || 0), 0) / 
      holdings.reduce((sum, h) => sum + (h.value || 0), 0) : 0,
    topPerformer: [...holdings].sort((a, b) => (b.change || 0) - (a.change || 0))[0],
    worstPerformer: [...holdings].sort((a, b) => (a.change || 0) - (b.change || 0))[0]
  };

  return {
    holdings,
    isLoading,
    error,
    refetch,
    addHolding,
    updateHolding,
    deleteHolding,
    portfolioSummary,
    fetchLatestPrices
  };
};
